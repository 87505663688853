<template>
  <div>
    <div class="chartContainer">
      <section class="card column is-4">
        <div class="card-content">
          <ul>
            <li>
              <div class="title is-6">
                <b-tooltip
                  class="control"
                  label="Indica quanto hai disponibile tra saldo dei conti e contanti. Le somme disponibili ma non spendibili a piacere sono indicate come vincolate."
                  position="is-bottom"
                  type="is-light"
                  :delay="600"
                  multilined
                  square
                >
                  <b-icon class="control" icon="information" size="is-small"></b-icon>
                </b-tooltip>Liquidità:
                <span class="liquidita">{{ $store.getters.liquidita }}</span> (
                <span class="vincolati">+ {{ $store.state.bonus }}</span> vincolati)
              </div>
            </li>
            <li style="padding-top: 5px">
              <div class="title is-6">
                <b-tooltip
                  class="control"
                  label="Indicatore globale riguardo la tua 'salute' finanziaria. Tiene conto della tua liquidità, debiti e crediti."
                  position="is-bottom"
                  type="is-light"
                  :delay="600"
                  multilined
                  square
                >
                  <b-icon class="control" icon="information" size="is-small"></b-icon>
                </b-tooltip>Situazione finanziaria:
                <span
                  class="finanziaria"
                >{{ $store.getters.situazioneFinanziaria }}</span>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
    <section class="chartContainer">
      <TrendPanel class="chart trendPanel" />
      <CategoryPanel class="chart" />
      <TrendCategoryPanel class="chart trendPanel" />
    </section>

    <div class="chartContainer">
      <BugdetMese class="chart trendPanel" />
      <Statistiche class="chart" />
    </div>

    <transition name="fade">
      <a v-show="showBtt" class="btt" role="button" @click="backToTop" title="Torna in cima">
        <b-icon icon="arrow-up-bold-circle" size="is-large" />
      </a>
    </transition>
  </div>
</template>

<script>
import TrendPanel from "@/components/TrendPanel.vue";
import CategoryPanel from "@/components/CategoryPanel.vue";
import TrendCategoryPanel from "@/components/TrendCategoryPanel.vue";
import Statistiche from "@/components/Statistiche.vue";
import BugdetMese from "@/components/BugdetMese.vue";

export default {
  name: "report",
  components: {
    TrendPanel,
    CategoryPanel,
    TrendCategoryPanel,
    Statistiche,
    BugdetMese
  },
  data: function() {
    return {
      showBtt: false
    };
  },
  methods: {
    backToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth"
      });
    }
  },
  beforeMount() {
    if (typeof window !== "undefined") {
      document.addEventListener("scroll", () => {
        this.showBtt = window.scrollY > 300;
      });
    }
  }
};
</script>

<style scoped>
.riassunto {
  text-align: center;
}

.trendPanel {
  width: 70%;
}
</style>
