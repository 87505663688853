<template>
  <div class="container">
    <div class="block">
      <nav class="level">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item">
            <b-dropdown aria-role="menu" v-if="$store.state.tabView == 'tabella'">
              <button class="button is-dark-warning" type="button" slot="trigger">
                <b-icon icon="menu"></b-icon>
                <span>Menù</span>
                <b-icon icon="menu-down"></b-icon>
              </button>

              <b-dropdown-item aria-role="menuitem" @click="isLoadExcelModalOpen = true">
                <div class="media">
                  <b-icon class="media-left" icon="file-excel"></b-icon>
                  <div class="media-content">
                    <h4>Importa Excel...</h4>
                  </div>
                </div>
              </b-dropdown-item>

              <div class="is-divider" data-content="Inserisci"></div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b-dropdown-item aria-role="menuitem" @click="isMovimentoModalOpen = true">
                        <div class="media">
                          <b-icon class="media-left" icon="basket"></b-icon>
                          <div class="media-content">
                            <h4>Movimento...</h4>
                          </div>
                        </div>
                      </b-dropdown-item>
                    </td>
                    <td>
                      <b-dropdown-item aria-role="menuitem" @click="isContantiModalOpen = true">
                        <div class="media">
                          <b-icon class="media-left" icon="cash-multiple"></b-icon>
                          <div class="media-content">
                            <h4>Contanti...</h4>
                          </div>
                        </div>
                      </b-dropdown-item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b-dropdown-item
                        aria-role="menuitem"
                        @click="isDebitiCreditiModalOpen = true"
                      >
                        <div class="media">
                          <b-icon class="media-left" icon="bank-transfer"></b-icon>
                          <div class="media-content">
                            <h4>Debiti e Crediti...</h4>
                          </div>
                        </div>
                      </b-dropdown-item>
                    </td>
                    <td>
                      <b-dropdown-item aria-role="menuitem" @click="isAltroModalOpen = true">
                        <div class="media">
                          <b-icon class="media-left" icon="podium"></b-icon>
                          <div class="media-content">
                            <h4>Benefits...</h4>
                          </div>
                        </div>
                      </b-dropdown-item>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="is-divider" data-content="Extra"></div>
              <b-dropdown-item aria-role="menuitem" @click="$router.push('/app/proceduraGuidata')">
                <div class="media">
                  <b-icon class="media-left" icon="ship-wheel"></b-icon>
                  <div class="media-content">
                    <h4>Avvia la procedura guidata...</h4>
                  </div>
                </div>
              </b-dropdown-item>

              <b-dropdown-item aria-role="menuitem" @click="isCriptaModalOpen = true">
                <div class="media">
                  <b-icon class="media-left" icon="shield-lock"></b-icon>
                  <div class="media-content">
                    <h4>Proteggi dati...</h4>
                  </div>
                </div>
              </b-dropdown-item>
              <b-dropdown-item aria-role="menuitem" custom>
                <div class="media">
                  <b-icon class="media-left" icon="draw"></b-icon>
                  <div class="media-content">
                    <h4>Regole categorizzazione automatica...</h4>
                  </div>
                  <div class="media-right">
                    <b-tag type="is-dark">Presto disponibile!</b-tag>
                  </div>
                </div>
              </b-dropdown-item>
              <!-- <b-dropdown-item aria-role="menuitem">
                <div class="media">
                  <b-icon class="media-left" icon="draw"></b-icon>
                  <div class="media-content">
                    <h4>Monitora movimenti periodici...</h4>
                  </div>
                  <div class="media-right">
                    <b-tag type="is-dark">Presto disponibile!</b-tag>
                  </div>
                </div>
              </b-dropdown-item>-->
              <b-dropdown-item aria-role="menuitem" custom>
                <div class="media">
                  <b-icon class="media-left" icon="export-variant"></b-icon>
                  <div class="media-content">
                    <h4>Esporta Dati...</h4>
                    <small>Scarica Excel con tutti i dati dell'applicazione</small>
                  </div>
                  <div class="media-right">
                    <b-tag type="is-dark">Presto disponibile!</b-tag>
                  </div>
                </div>
              </b-dropdown-item>
              <b-dropdown-item aria-role="menuitem" @click="isDonazioneModalOpen = true">
                <div class="media">
                  <b-icon class="media-left" icon="charity"></b-icon>
                  <div class="media-content">
                    <h4>Donazione...</h4>
                    <small>Se puoi permettertelo, aiutaci con un contributo</small>
                  </div>
                </div>
              </b-dropdown-item>

              <b-dropdown-item aria-role="menuitem" separator></b-dropdown-item>
              <b-dropdown-item aria-role="menuitem" @click="confermaCancellaTutto">
                <div class="media">
                  <b-icon class="media-left" icon="delete-circle"></b-icon>
                  <div class="media-content">
                    <h4>Cancella tutto...</h4>
                    <small>Rimuove i dati da questo browser<div v-show="$store.state.synchInCloud"> e dal cloud</div></small>
                  </div>
                </div>
              </b-dropdown-item>

              <div class="is-divider" data-content="Online"></div>
              <b-dropdown-item aria-role="menuitem" @click="changeUseOfCloud">
                <div class="media">
                  <!--<b-icon class="media-left" icon="cloud"></b-icon>-->
                  <img
                    class="media-left"
                    src="../assets/google.svg"
                    style="width: 42px; height: 42px"
                    alt="Google Cloud"
                    v-show="!$store.state.synchInCloud"
                  />
                  <img
                    class="media-left"
                    src="../assets/google_disabled.svg"
                    style="width: 42px; height: 42px"
                    alt="Google Cloud"
                    v-show="$store.state.synchInCloud"
                  />
                  <div class="media-content" v-show="!$store.state.synchInCloud">
                    <h4>Collegati al Cloud...</h4>
                    <small>Trasferisci e gestisci i tuoi dati ‘Come stò’ online</small>
                  </div>
                  <div class="media-content" v-show="$store.state.synchInCloud">
                    <h4>Scollegati dal Cloud...</h4>
                    <small>Gestisci i tuoi dati ‘Come stò’ offline</small>
                  </div>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div style="color: #737171d6" v-if="$store.state.tabView == 'tabella'">
            <p
              class="is-6"
              v-if="$store.state.synchInCloud && $store.state.cloudState=='progress'"
            >Sincronizzazione Cloud in corso...</p>
            <p
              class="is-6"
              v-if="$store.state.synchInCloud && $store.state.cloudState=='sync'"
            >Salvato in Cloud</p>
            <p
              class="is-6"
              v-if="$store.state.synchInCloud && $store.state.cloudState=='error'"
            >Errore sincronizzazione in Cloud</p>
          </div>
        </div>
        <!-- Right side -->
        <div class="level-right">
          <div class="level-item">
            <b-field gropued>
              <b-tooltip
                class="control"
                label="Passa da una vista all'altra. 'Movimenti' permette di categorizzare e organizzare i dati mentre, 'Report' ti consentirà di analizzare la tua situazione e capire ‘Come stò’."
                position="is-bottom"
                type="is-light"
                :delay="600"
                multilined
                square
              >
                <b-icon class="control" icon="information" size="is-small"></b-icon>
              </b-tooltip>
              <p class="control">
                <b-button
                  icon="table-large"
                  size="is-medium"
                  :type="$store.state.tabView == 'tabella' ? 'is-dark-warning' : 'is-action-button'"
                  @click="$store.state.tabView='tabella'"
                >
                  <div class="media">
                    <b-icon class="media-left" icon="table-large"></b-icon>
                    <div class="media-content">Movimenti</div>
                  </div>
                </b-button>
              </p>
              <p class="control">
                <b-button
                  icon="table-large"
                  size="is-medium"
                  :type="$store.state.tabView == 'report' ? 'is-dark-warning' : 'is-action-button'"
                  @click="$store.state.tabView = 'report'"
                  :disabled="this.$store.getters.datiVisibili.length == 0"
                >
                  <div class="media">
                    <b-icon class="media-left" icon="chart-areaspline"></b-icon>
                    <div class="media-content">Report</div>
                  </div>
                </b-button>
              </p>
            </b-field>
          </div>
        </div>
      </nav>
    </div>

    <b-modal
      :active.sync="isLoadExcelModalOpen"
      trap-focus
      aria-role="dialog"
      aria-modal
      has-modal-card
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Importa Excel</p>
        </header>
        <section class="modal-card-body">
          <LoadExcel v-on:loaded="isLoadExcelModalOpen=false; resetFilters()" />
        </section>
      </div>
    </b-modal>

    <b-modal
      :active.sync="isMovimentoModalOpen"
      trap-focus
      aria-role="dialog"
      aria-modal
      has-modal-card
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Inserisci movimento</p>
        </header>
        <section class="modal-card-body">
          <Movimento
            labelDoneButton="Aggiungi"
            v-on:done="isMovimentoModalOpen = false; resetFilters(); $refs.categorizza.highlightDati([$event])"
          />
        </section>
      </div>
    </b-modal>

    <b-modal
      :active.sync="isContantiModalOpen"
      trap-focus
      aria-role="dialog"
      aria-modal
      has-modal-card
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Quanti contanti hai?</p>
        </header>
        <section class="modal-card-body">
          <Contanti
            labelDoneButton="Salva"
            @done="isContantiModalOpen = false; openFinancialOperationDoneToast()"
          />
        </section>
      </div>
    </b-modal>
    <b-modal
      :active.sync="isDebitiCreditiModalOpen"
      trap-focus
      aria-role="dialog"
      aria-modal
      has-modal-card
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Debiti e Crediti</p>
        </header>
        <section class="modal-card-body">
          <DebitiCrediti
            labelDoneButton="Salva"
            v-on:done="isDebitiCreditiModalOpen = false; openFinancialOperationDoneToast()"
          />
        </section>
      </div>
    </b-modal>
    <b-modal
      :active.sync="isAltroModalOpen"
      trap-focus
      aria-role="dialog"
      aria-modal
      has-modal-card
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Altro</p>
        </header>
        <section class="modal-card-body">
          <Altro
            labelDoneButton="Salva"
            v-on:done="isAltroModalOpen = false; openFinancialOperationDoneToast()"
          />
        </section>
      </div>
    </b-modal>

    <Cripta :modalOpen.sync="isCriptaModalOpen" />

    <Donazione :modalOpen.sync="isDonazioneModalOpen" />

    <div v-show="this.$store.state.dati.length == 0">
      <section class="section" style="padding-top: 0em">
        <LoadExcel v-on:loaded="resetFilters" />
        <p>Oppure</p>
        <br />
        <p>
          <b-button type="is-warning" @click="loadExampleDati">Carica dati di esempio casuali</b-button>
        </p>
      </section>
    </div>

    <div v-show="this.$store.state.dati.length != 0">
      <div v-show="$store.state.tabView == 'tabella'">
        <Categorizza
          :readOnly="false"
          ref="categorizza"
          @hideShowAction="renderedGraficiOnce = false"
          @changeCategoryRuleExecuted="renderedGraficiOnce = false"
        />
        <Adsense
          v-if="$store.state.tabView == 'tabella'"
          class="comeStoADS"
          data-ad-client="ca-pub-8018925037718321"
          data-ad-slot="6041933438"
          data-ad-format="auto"
          :data-full-width-responsive="true"
        ></Adsense>
      </div>
      <div
        v-if="$store.state.tabView == 'report' || renderedGraficiOnce"
        v-show="$store.state.tabView == 'report'"
      >
        <Report @hook:mounted="renderedGraficiOnce=true" />
        <Adsense
          class="comeStoADS"
          data-ad-client="ca-pub-8018925037718321"
          data-ad-slot="2032683464"
          data-ad-format="auto"
          :data-full-width-responsive="true"
        ></Adsense>
      </div>
    </div>
  </div>
</template>

<script>
import Categorizza from "@/components/import/Categorizza.vue";
import LoadExcel from "@/components/import/LoadExcel.vue";
import Movimento from "@/components/import/Movimento.vue";
import Contanti from "@/components/import/Contanti.vue";
import DebitiCrediti from "@/components/import/DebitiCrediti.vue";
import Altro from "@/components/import/Altro.vue";
import Report from "./Report.vue";
import Donazione from "@/components/Donazione.vue";
import Cripta from "@/components/Cripta.vue";

export default {
  name: "ComeSto",
  components: {
    Categorizza,
    LoadExcel,
    Movimento,
    Contanti,
    DebitiCrediti,
    Altro,
    Report,
    Donazione,
    Cripta
  },
  data: function() {
    return {
      isLoadExcelModalOpen: false,
      isMovimentoModalOpen: false,
      isContantiModalOpen: false,
      isDebitiCreditiModalOpen: false,
      isAltroModalOpen: false,
      isCriptaModalOpen: false,
      isDonazioneModalOpen: false,
      renderedGraficiOnce: false,
      loadingExampleData: false
    };
  },
  methods: {
    changeUseOfCloud() {
      this.$store.dispatch("changeUseOfCloud").then(synchInCloud => {
        this.$buefy.toast.open({
          duration: 5000,
          message: synchInCloud
            ? "I tuoi movimenti saranno d'ora in avanti sincronizzati in Cloud"
            : "I tuoi movimenti NON sono più sincronizzati in Cloud",
          position: "is-bottom",
          type: "is-dark"
        });
      });
    },
    confermaCancellaTutto() {
      let message = this.$store.state.synchInCloud
        ? "<p>Attenzione, vuoi <b>rimuovere</b> tutti i dati da questo browser e dal cloud? <br/>Il cloud si scollegerà e sarà necessario ricollegarsi manualmente tramite l'apposita voce di menù.</p> <br> L'azione non è reversibile."
        : "<p>Attenzione, vuoi <b>rimuovere</b> tutti i dati da questo browser?</p> <br> L'azione non è reversibile.";

      this.$buefy.dialog.confirm({
        title: "Conferma cancellazione",
        message: message,
        confirmText: "Sono sicuro, cancella tutto",
        cancelText: "Ho cambiato idea",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          const loadingComponent = this.$buefy.loading.open();
          this.$store
            .dispatch("resetState")
            .then(() => loadingComponent.close());
        }
      });
    },
    loadExampleDati() {
      this.loadingExampleData = true;

      let doLoadExampleDati = async () => {
        let loadedObj = {};
        this.$store.dispatch("loadExampleDati", loadedObj);
        this.showLoaded(loadedObj.dati.length);

        this.resetFilters();
      };
      doLoadExampleDati();
    },
    showLoaded(total) {
      this.loadingExampleData = false;
      this.$buefy.toast.open({
        duration: 5000,
        message: "Caricati " + total + " movimenti con successo",
        position: "is-bottom",
        type: "is-success"
      });
    },
    resetFilters() {
      this.renderedGraficiOnce = false;
      this.$refs.categorizza.resetFilters();
    },
    openFinancialOperationDoneToast() {
      this.$buefy.toast.open({
        duration: 2500,
        message:
          "Operazione salvata con successo. Cliccare su 'Report' per vederne gli effetti",
        position: "is-bottom",
        type: "is-dark"
      });
    }
  },
  beforeMount() {
    if (
      !this.$store.state.encrypted ||
      (this.$store.state.encrypted && this.$store.state.encryptionKey != null)
    ) {
      if (!this.$store.state.isStateFullyLoaded) {
        const loadingComponent = this.$buefy.loading.open();
        this.$store.dispatch("loadState").then(() => {
          loadingComponent.close();
          this.resetFilters();

          this.$store.dispatch("turnOnAskPasswordProtection");
        });
      } else {
        this.$store.dispatch("turnOnAskPasswordProtection");
      }
    } else {
      this.$router.push("/app/protected");
    }
  }
};
</script>

<style scoped>
.is-divider {
  margin: 1em;
}

.comeStoADS {
  text-align: center;
  height: 90px;
  margin-bottom: 20px;
}
</style>