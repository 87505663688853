<script>
import { Bar } from "vue-chartjs";
import moment from "moment";

export default {
  extends: Bar,
  props: ["trendData", "groupedBy"],
  methods: {
    handler(dati) {
      let entrate = dati[0];
      let uscite = dati[1];
      let timeline = this.arrayUnique(
        entrate
          .concat(uscite)
          .sort((a, b) => {
            let dataA = moment(a.data_valuta);
            let dataB = moment(b.data_valuta);
            return dataA.isBefore(dataB) ? -1 : dataA.isAfter(dataB) ? 1 : 0;
          })
          .map(e => this.convertDate(e.data_valuta))
      );

      let entrateDataset = this.createDataset(timeline, entrate);
      let usciteDataset = this.createDataset(timeline, uscite);
      let mediaDataset = timeline.map(
        entry => entrateDataset[entry] + usciteDataset[entry]
      );

      let tmpData = {
        labels: timeline
      };
      tmpData.datasets = [
        {
          type: "line",
          label: "Risparmio",
          backgroundColor: "rgba(159,255,247,0.74)",
          data: mediaDataset.map(media => Math.round(media * 100) / 100)
        },
        {
          label: "Entrate",
          backgroundColor: "rgba(78,163,78,0.89)",
          data: timeline.map(time => {
            if (entrateDataset[time] == undefined) {
              return 0;
            } else {
              return Math.round(entrateDataset[time] * 100) / 100;
            }
          })
        },
        {
          label: "Uscite",
          backgroundColor: "rgba(196,67,67,0.88)",
          data: timeline.map(time => {
            if (usciteDataset[time] == undefined) {
              return 0;
            } else {
              return Math.round(usciteDataset[time] * 100) / 100;
            }
          })
        }
      ];
      this.renderChart(tmpData, this.options);
    },
    arrayUnique(array) {
      var a = array.concat();
      for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
          if (a[i] === a[j]) a.splice(j--, 1);
        }
      }
      return a;
    },
    createDataset(timeline, records) {
      let dataset = {};
      timeline.forEach(entry => {
        dataset[entry] = 0;
      });
      records.forEach(element => {
        let key = this.convertDate(element.data_valuta);
        dataset[key] += Number(element.importo);
      });
      return dataset;
    },
    convertDate(date) {
      let castedDate = new Date(date);
      let convertedDate;
      switch (this.groupedBy) {
        case "week":
          convertedDate = "W".concat(moment(castedDate).format("WW/YY"));
          break;
        case "month":
          convertedDate = moment(castedDate).format("MMMM YYYY");
          break;
        case "year":
          convertedDate = moment(castedDate).format("YYYY");
          break;
      }
      return convertedDate;
    }
  },
  data: function() {
    let self = this;
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        onClick(event, elements) {
          if (elements.length != 0) {
            self.$emit("dettaglio-click", elements[0]._index);
          }
        }
      }
    };
  },
  watch: {
    trendData(dati) {
      this.handler(dati);
    }
  },
  mounted() {
    this.handler(this.trendData);
  }
};
</script>
