<template>
  <div class="card">
    <div class="card-content">
      <div class="columns is-centered">
        <div class="column is-narrow">
          <b-tooltip
            class="control"
            label="Questo grafico riporta le entrate e le uscite nel tempo per il periodo selezionato. Il 'risparmio' indica se c'è stato un guadagno o una perdita."
            position="is-bottom"
            type="is-light"
            :delay="600"
            multilined
            square
          >
            <b-icon class="control" icon="information" size="is-small"></b-icon>
            <h4 class="is-size-5">Andamento generale</h4>
          </b-tooltip>
        </div>
      </div>

      <div class="columns is-centered is-multiline">
        <div class="column is-narrow">
          <b-field label-position="inside" grouped group-multiline>
            <template slot="label">
              Periodo
              <b-switch
                :value="false"
                type="is-primary"
                size="is-small"
                style="margin-left: 1em; vertical-align: bottom"
                @input="periodoSwitchPressed"
              >
                <span class="has-text-primary is-italic">Ultimi 6 mesi</span>
              </b-switch>
            </template>
            <!-- <div class="columns is-centered control" style="flex-direction: column"> -->
            <b-datepicker
              :min-date="minDatePeriod"
              :max-date="maxDatePeriod"
              v-model="datePeriod"
              range
              nearby-selectable-month-days
              :years-range="[-100,+100]"
            ></b-datepicker>
            <div class="control"></div>
            <!-- </div> -->
          </b-field>
        </div>
        <div class="column is-narrow">
          <b-field label="Raggruppa" label-position="inside">
            <b-select icon="earth" v-model="groupingCriteria" expanded>
              <option value="week">Settimana</option>
              <option value="month">Mese</option>
              <option value="year">Anno</option>
            </b-select>
          </b-field>
        </div>
      </div>

      <b-modal
        :active.sync="isDettaglioMovimentiModalOpen"
        trap-focus
        aria-role="dialog"
        aria-modal
        has-modal-card
      >
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Movimenti selezionati</p>
          </header>
          <section class="modal-card-body">
            <!-- <b-button
              @click="isDettaglioMovimentiModalOpen=false; $store.commit('switchToMovimentiView')"
            >Apri grande</b-button>-->
            <Categorizza ref="categorizza" :readOnly="true" @hook:mounted="loadMovimentiModal" />
          </section>
        </div>
      </b-modal>

      <span>
        <TrendChart
          :trendData="trendData"
          :groupedBy="groupingCriteria"
          @dettaglio-click="showMovimentiModal"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import moment from "moment";
import TrendChart from "@/components/TrendChart.vue";
import Categorizza from "@/components/import/Categorizza.vue";

export default {
  name: "TrendPanel",
  components: {
    TrendChart,
    Categorizza
  },
  props: ["flusso"],
  data: function() {
    return {
      datePeriod: [],
      groupingCriteria: "month",
      isDettaglioMovimentiModalOpen: false,
      clickedIndex: -1
    };
  },
  computed: {
    minDatePeriod: function() {
      return new Date(
        Math.min.apply(
          null,
          this.$store.getters.datiVisibili.map(entry => entry.data_valuta)
        )
      );
    },
    maxDatePeriod: function() {
      return new Date(
        Math.max.apply(
          null,
          this.$store.getters.datiVisibili.map(entry => entry.data_valuta)
        )
      );
    },
    trendData: function() {
      return [
        this.$store.getters.groupDatiBy(
          "entrate",
          this.groupingCriteria,
          this.datePeriod[0],
          this.datePeriod[1]
        ),
        this.$store.getters.groupDatiBy(
          "uscite",
          this.groupingCriteria,
          this.datePeriod[0],
          this.datePeriod[1]
        )
      ];
    }
  },
  methods: {
    periodoSwitchPressed(enabled) {
      if (enabled) {
        let startDate = new Date(this.maxDatePeriod.getTime());
        startDate.setMonth(this.maxDatePeriod.getMonth() - 6);

        if (moment(startDate).isBefore(this.minDatePeriod)) {
          startDate = this.minDatePeriod;
        }
        this.datePeriod = [startDate, this.maxDatePeriod];
        this.groupingCriteria = "month";
      } else {
        this.datePeriod = [this.minDatePeriod, this.maxDatePeriod];
      }
    },
    showMovimentiModal(newClickedIndex) {
      this.clickedIndex = newClickedIndex;
      this.isDettaglioMovimentiModalOpen = true;
    },
    loadMovimentiModal() {
      let startDate = moment(new Date(this.datePeriod[0].getTime()));
      let endDate;
      switch (this.groupingCriteria) {
        case "week":
          startDate.startOf("week");
          startDate.add(this.clickedIndex, "weeks");

          endDate = moment(startDate);
          endDate.endOf("week");
          break;
        case "month":
          startDate.startOf("month");
          startDate.add(this.clickedIndex, "months");

          endDate = moment(startDate);
          endDate.endOf("month");
          break;
        case "year":
          startDate.startOf("year");
          startDate.add(this.clickedIndex, "years");

          endDate = moment(startDate);
          endDate.endOf("year");
          break;
      }

      this.$refs.categorizza.onTrendGraficiClicked([
        startDate.toDate(),
        endDate.toDate()
      ]);
    }
  },
  beforeMount: function() {
    this.datePeriod = [this.minDatePeriod, this.maxDatePeriod];
  }
};
</script>