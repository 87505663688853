<script>
import { Bar } from "vue-chartjs";
import moment from "moment";

export default {
  extends: Bar,
  props: ["chartData", "categoriaIndex"],
  methods: {
    handler(dati) {
      let entrate = dati.filter(dato => dato.importo >= 0);
      let uscite = dati.filter(dato => dato.importo < 0);

      let timeline = this.arrayUnique(
        entrate
          .concat(uscite)
          .sort((a, b) => {
            let dataA = moment(a.data_valuta);
            let dataB = moment(b.data_valuta);
            return dataA.isBefore(dataB) ? -1 : dataA.isAfter(dataB) ? 1 : 0;
          })
          .map(e => this.convertDate(e.data_valuta))
      );

      let entrateDataset = this.createDataset(timeline, entrate);
      let usciteDataset = this.createDataset(timeline, uscite);

      let tmpData = {
        labels: timeline,
        datasets: []
      };

      tmpData.datasets = [{
        label: this.$store.state.categorie[this.categoriaIndex].name,
        backgroundColor: this.$store.state.categorie[this.categoriaIndex].color,
        data: timeline.map(time => {
          // console.log(
          //   cat,
          //   catIndex,
          //   time,
          //   entrateDataset[catIndex][time],
          //   usciteDataset[catIndex][time]
          // );
          if (
            entrateDataset[time] == undefined &&
            usciteDataset[time] == undefined
          ) {
            return 0;
          } else if (entrateDataset[time] != 0) {
            return Math.round(entrateDataset[time] * 100) / 100;
          } else if (usciteDataset[time] != 0) {
            return Math.round(usciteDataset[time] * 100) / 100;
          } else {
            return 0;
          }
        })
      }];

      this.renderChart(tmpData, this.options);
    },
    arrayUnique(array) {
      var a = array.concat();
      for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
          if (a[i] === a[j]) a.splice(j--, 1);
        }
      }
      return a;
    },
    createDataset(timeline, records) {
      let dataset = {};
      timeline.forEach(entry => {
        dataset[entry] = 0;
      });
      records.forEach(element => {
        let key = this.convertDate(element.data_valuta);
        dataset[key] += Number(element.importo);
      });
      return dataset;
    },
    convertDate(date) {
      let castedDate = new Date(date);
      let convertedDate = moment(castedDate).format("MMMM YYYY");
      return convertedDate;
    }
  },
  data: function() {
    let self = this;
    return {
      currentData: this.chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ]
        },
        onClick(event, elements) {
          if (elements.length != 0) {
            self.$emit("dettaglio-click", moment(elements[0]._xScale._ticks[elements[0]._index].label, "MMMM YYYY").toDate());
          }
        }
      }
    };
  },
  watch: {
    chartData(dati) {
      this.currentData = dati;
      this.handler(dati);
    },
    categoriaIndex(dati) {
      this.handler(this.currentData);
    }
  },
  mounted() {
    this.handler(this.chartData);
  }
};
</script>
