<script>
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,
  props: ["chartData", "flusso"],
  methods: {
    handler(dati) {
      let tmpData = {
        labels: this.$store.state.categorie.map(cat => cat.name)
      };

      tmpData.datasets = [];
      if (this.flusso == "IN" || this.flusso == "BOTH") {
        let totalSum = dati[0].reduce(
          (sum, element) => sum + Math.abs(element),
          0
        );
        tmpData.datasets.push(this.createDataset(totalSum, dati[0], "Entrate"));
      }
      if (this.flusso == "OUT" || this.flusso == "BOTH") {
        let totalSum = dati[1].reduce(
          (sum, element) => sum + Math.abs(element),
          0
        );
        tmpData.datasets.push(this.createDataset(totalSum, dati[1], "Uscite"));
      }

      this.renderChart(tmpData, this.options);
    },
    createDataset(totalSum, records, name) {
      return {
        label: name,
        backgroundColor: this.$store.state.categorie.map(cat => cat.color),
        data: records.map(
          e => Math.round((Math.abs(e) / totalSum) * 100 * 100) / 100
        )
      };
    }
  },
  data: function() {
    let self = this;
    return {
      currentData: this.chartData,
      options: {
        cutoutPercentage: 30,
        rotation: 3 * Math.PI,
        circumference: Math.PI,
        legend: {
          display: false,
          labels: {
            generateLabels: function(chart) {
              var data = chart.data;
              if (data.labels.length && data.datasets.length) {
                return data.labels.map(function(label, i) {
                  return {
                    text: label,
                    fillStyle: self.$store.state.categorie[i].color,
                    strokeStyle: "#000000",
                    lineWidth: 1,
                    hidden: false,

                    // Extra data used for toggling the correct item
                    index: i
                  };
                });
              }
              return [];
            }
          }
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var label = "";
              if (this.flusso == "BOTH") {
                label += data.datasets[tooltipItem.datasetIndex].label + " - ";
              }

              label += self.$store.state.categorie[tooltipItem.index].name;

              if (label) {
                label += ": ";
              }
              label +=
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              label += "%";

              return label;
            }
          }
        },
        onClick(event, elements) {
          if (elements.length != 0) {
            self.$emit("dettaglio-click", elements[0]._index);
          }
        }
      }
    };
  },
  watch: {
    chartData(dati) {
      this.currentData = dati;
      this.handler(dati);
    },
    flusso(dati) {
      this.handler(this.currentData);
    }
  },
  mounted() {
    this.handler(this.chartData);
  }
};
</script>