<template>
  <div class="card">
    <div class="card-content">
      <div class="columns is-centered">
        <div class="column is-narrow">
          <b-tooltip
            class="control"
            label="Viene riportato l'importo mensile necessario per ottenere la stessa cifra annuale per ogni categoria basandosi sui movimenti passati. In altre parole, permette di conoscere il budget mensile per ogni categoria e poter applicare il metodo delle 'buste'."
            position="is-bottom"
            type="is-light"
            :delay="600"
            multilined
            square
          >
            <b-icon class="control" icon="information" size="is-small"></b-icon>
            <h4 class="is-size-5">Bugdet Mese</h4>
          </b-tooltip>
        </div>
      </div>
    </div>
    <div class="card-content">
      <b-table :data="false ? [] : budget" narrowed hoverable style=" margin-left:auto; margin-right:auto;">
        <template slot-scope="props">
          <b-table-column field="categoria" label="Categoria">
            <span
              :style="'background-color: '+ props.row.color +'; padding: 3px; padding-right: 20px; border: 1px solid green; margin-right: 1em'"
            />
            {{ props.row.categoria }}
          </b-table-column>

          <b-table-column field="amount" label="Importo" centered>{{ props.row.importo }}</b-table-column>
        </template>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="emoticon-sad" size="is-large"></b-icon>
              </p>
              <p>Sono necessari i movimenti dell'intero anno precedente per poter calcolare questo specchietto.</p>
            </div>
          </section>
        </template>
      </b-table>

      <br />
      <b-message>
        <p>
          Questo specchietto riporta l'importo mensile necessario per ottenere la stessa cifra totale annualmente. I dati si basano sui movimenti dell'anno precedente.
          <br />Ben si presta per adottare il metodo delle 'buste'!
        </p>
        <br />
        <p style="text-align: left; font-size: 0.75em">
          Esempio:
          <br />L'anno scorso sono stati spesi 1200 Euro nella categoria 'Cibo' durante tutto l'anno. Quindi, distribuendo uniformemente la spesa lo specchietto riporterà che sono necessari 100 Euro al mese per la categoria 'Cibo' (100 Euro x 12 mesi = 1200 Euro annui).
        </p>
      </b-message>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "budgetMese",
  computed: {
    budget() {
      let previousYear = moment().subtract(1, "years");
      console.log(
        "Calcolo Budget Mese anno di riferimeto:",
        previousYear.year()
      );

      return this.$store.state.categorie
        .map((categoria, index) => {
          let groupedData = this.$store.getters.groupDatiBy(
            "categoria",
            "month",
            previousYear.startOf("year").toDate(),
            previousYear.endOf("year").toDate(),
            index
          );
          console.log(groupedData);
          return {
            categoria: categoria.name,
            color: categoria.color,
            importo: Math.round(
              groupedData.reduce(
                (total, current) => total + current.importo,
                0
              ) / 12
            )
          };
        })
        .filter(elem => elem.importo != 0);
    }
  }
};
</script>