<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: ["chartData", "flusso"],
  methods: {
    handler(dati) {
      let tmpData = {
        labels: this.$store.state.categorie.map(cat => cat.name)
      };

      tmpData.datasets = [];
      if (this.flusso == "IN" || this.flusso == "BOTH") {
        tmpData.datasets.push(this.createDataset(dati[0], "Entrate"));
      }
      if (this.flusso == "OUT" || this.flusso == "BOTH") {
        tmpData.datasets.push(this.createDataset(dati[1], "Uscite"));
      }

      this.renderChart(tmpData, this.options);
    },
    createDataset(records, name) {
      return {
        label: name,
        backgroundColor: this.$store.state.categorie.map(cat => cat.color),
        data: records.map(e => Math.round(Math.abs(e) * 100) / 100)
      };
    }
  },
  data: function() {
    let self = this;
    return {
      currentData: this.chartData,
      options: {
        legend: {
          display: false,
          labels: {
            generateLabels: function(chart) {
              var data = chart.data;
              if (data.labels.length && data.datasets.length) {
                return data.labels.map(function(label, i) {
                  return {
                    text: label,
                    fillStyle: self.$store.state.categorie[i].color,
                    strokeStyle: "#000000",
                    lineWidth: 1,
                    hidden: false,

                    // Extra data used for toggling the correct item
                    index: i
                  };
                });
              }
              return [];
            }
          }
        },
        onClick(event, elements) {
          if (elements.length != 0) {
            self.$emit("dettaglio-click", elements[0]._index);
          }
        }
      }
    };
  },
  watch: {
    chartData(dati) {
      this.currentData = dati;
      this.handler(dati);
    },
    flusso(dati) {
      this.handler(this.currentData);
    }
  },
  mounted() {
    this.handler(this.chartData);
  }
};
</script>