<template>
  <b-modal :active.sync="show" trap-focus aria-role="dialog" aria-modal has-modal-card>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Proteggi dati</p>
      </header>
      <section class="modal-card-body">
        <div class="is-mobile">
          <div class="is-rounded">
            <div class="card-content columns" style="flex-direction: column; align-items: center">
              <div class="column" v-show="$store.state.encrypted">
                <b-button type="is-danger" size="is-large" @click="uncripta">Elimina password</b-button>
                <br />
                <br />Oppure
              </div>

              <div class="column">
                <b-message v-show="showPasswordError" type="is-danger" has-icon>
                  Errore:
                  <br />Le password inserite non concidono.
                </b-message>
                <p>
                  Inserisci o aggiorna password per proteggere in modo sicuro i tuoi dati.
                  <br />Verrano criptati secondo l'algoritmo
                  <a
                    href="https://it.wikipedia.org/wiki/Advanced_Encryption_Standard"
                    target="_blank"
                  >AES</a>.
                  <br />
                  <b>Attenzione: non è possibile recuperare la password se dimenticata.</b>
                </p>
              </div>

              <div class="column">
                <b-field label="Password" label-position="inside" grouped>
                  <b-input type="password" v-model="password" password-reveal></b-input>
                </b-field>
                <b-field label="Ripeti Password" label-position="inside" grouped>
                  <b-input type="password" v-model="repeatedPassword" password-reveal></b-input>
                </b-field>
              </div>

              <div class="column">
                <p>Per garantire la massima privacy, ogni 30 minuti l'applicazione richiederà l'inserimento della password per continuare.</p>
              </div>

              <div class="column buttons is-centered">
                <b-button
                  type="is-success"
                  size="is-large"
                  @click="cripta"
                  :disabled="password == '' || repeatedPassword == ''"
                >Imposta</b-button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "cripta",
  props: ["modalOpen"],
  data: function() {
    return {
      password: "",
      repeatedPassword: "",
      showPasswordError: false
    };
  },
  computed: {
    show: {
      get() {
        return this.modalOpen;
      },
      set(value) {
        this.$emit("update:modalOpen", value);
      }
    }
  },
  methods: {
    cripta() {
      if (this.password != this.repeatedPassword) {
        this.showPasswordError = true;
      } else {
        this.showPasswordError = false;

        this.$store.dispatch("encryptState", this.password);
        this.$store.dispatch("turnOnAskPasswordProtection");
        this.password = "";
        this.repeatedPassword = "";
        this.show = false;
        this.$buefy.toast.open({
          duration: 2500,
          message:
            "‘Come stò’ protetto e i dati sono stati criptati con successo.",
          position: "is-bottom",
          type: "is-dark"
        });
      }
    },
    uncripta() {
      this.$store.dispatch("unencryptState", null);
      this.$store.dispatch("turnOffAskPasswordProtection");
      this.password = "";
      this.repeatedPassword = "";
      this.show = false;
      this.$buefy.toast.open({
        duration: 2500,
        message: "Password rimossa. I dati sono criptati in modo meno sicuro.",
        position: "is-bottom",
        type: "is-dark"
      });
    }
  }
};
</script>