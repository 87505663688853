<template>
  <div class="card">
    <div class="card-content">
      <div class="columns is-centered">
        <div class="column is-narrow">
          <b-tooltip
            class="control"
            label="Questo grafico visualizza come è variata nel tempo una certa categoria."
            position="is-bottom"
            type="is-light"
            :delay="600"
            multilined
            square
          >
            <b-icon class="control" icon="information" size="is-small"></b-icon>
            <h4 class="is-size-5">Variazione singola categoria nel tempo</h4>
          </b-tooltip>
        </div>
      </div>

      <div class="columns is-centered is-multiline">
        <div class="column is-narrow">
          <b-field label="Dal" label-position="inside" grouped group-multiline>
            <b-datepicker
              :min-date="minDatePeriod"
              :max-date="dateEnd != null ? dateEnd : maxDatePeriod"
              v-model="dateStart"
              type="month"
              :date-formatter="getMeseFormatter"
              :years-range="[-100,+100]"
            ></b-datepicker>
            <div class="control"></div>
          </b-field>
        </div>
        <div class="column is-narrow">
          <b-field label="Al" label-position="inside" grouped group-multiline>
            <b-datepicker
              :min-date="dateStart != null ? dateStart : minDatePeriod"
              :max-date="maxDatePeriod"
              v-model="dateEnd"
              type="month"
              :date-formatter="getMeseFormatter"
              nearby-selectable-month-days
              :years-range="[-100,+100]"
            ></b-datepicker>
            <div class="control"></div>
          </b-field>
        </div>
        <div class="column is-narrow">
          <b-field label="Categoria:" horizontal grouped group-multiline>
            <b-dropdown class="categoria-dropdown" v-model="categoriaIndex">
              <button
                type="button"
                class="button is-primary"
                slot="trigger"
              >{{ resolveCategoria(categoriaIndex) }}</button>
              <template v-for="(categoria, index) in categorie">
                <b-dropdown-item :value="index" :key="index">{{ categoria.name }}</b-dropdown-item>
              </template>
            </b-dropdown>
          </b-field>
        </div>
      </div>

      <b-modal
        :active.sync="isDettaglioMovimentiModalOpen"
        trap-focus
        aria-role="dialog"
        aria-modal
        has-modal-card
      >
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Movimenti selezionati</p>
          </header>
          <section class="modal-card-body">
            <Categorizza ref="categorizza" :readOnly="true" @hook:mounted="loadMovimentiModal" />
          </section>
        </div>
      </b-modal>

      <span>
        <TrendCategoryChart
          :chartData="chartData"
          :categoriaIndex="categoriaIndex"
          @dettaglio-click="showMovimentiModal"
        />
      </span>
    </div>
  </div>
</template>

<script>
import TrendCategoryChart from "@/components/TrendCategoryChart.vue";
import Categorizza from "@/components/import/Categorizza.vue";
import moment from "moment";

export default {
  name: "TrendCategoryPanel",
  components: {
    TrendCategoryChart,
    Categorizza
  },
  data: function() {
    return {
      dateStart: null,
      dateEnd: null,
      isDettaglioMovimentiModalOpen: false,
      categoriaIndex: this.$store.getters.altreSpeseCategoriaIndex,
      clickedMonthDate: null
    };
  },
  methods: {
    resolveCategoria(index) {
      return this.$store.state.categorie[index].name;
    },
    getMeseFormatter(date) {
      moment.locale("it");
      let displayMese = moment(date).format("MMMM YYYY");
      return displayMese.charAt(0).toUpperCase() + displayMese.substring(1);
    },
    showMovimentiModal(newClickedMonthDate) {
      this.clickedMonthDate = newClickedMonthDate;
      this.isDettaglioMovimentiModalOpen = true;
    },
    loadMovimentiModal() {
      this.$refs.categorizza.onTrendCategoryGraficiClicked({
        month: this.clickedMonthDate,
        category: this.categoriaIndex
      });
    }
  },
  computed: {
    categorie() {
      return this.$store.state.categorie;
    },
    minDatePeriod: function() {
      return new Date(
        Math.min.apply(
          null,
          this.$store.getters.datiVisibili.map(entry => entry.data_valuta)
        )
      );
    },
    maxDatePeriod: function() {
      return new Date(
        Math.max.apply(
          null,
          this.$store.getters.datiVisibili.map(entry => entry.data_valuta)
        )
      );
    },
    chartData: function() {
      let groupedData = this.$store.getters.groupDatiBy(
        "categoria",
        "month",
        moment(this.dateStart)
          .startOf("month")
          .toDate(),
        moment(this.dateEnd)
          .endOf("month")
          .toDate(),
        this.categoriaIndex
      );
      return groupedData;
    }
  },
  beforeMount: function() {
    this.dateStart = this.minDatePeriod;
    this.dateEnd = this.maxDatePeriod;
  }
};
</script>