<template>
  <div class="card">
    <div class="card-content">
      <div class="columns is-centered">
        <div class="column is-narrow">
          <b-tooltip
            class="control"
            label="Sono riportati alcuni dati di riepilogo e di carattere statistico"
            position="is-bottom"
            type="is-light"
            :delay="600"
            multilined
            square
          >
            <b-icon class="control" icon="information" size="is-small"></b-icon>
            <h4 class="is-size-5">Curiosità e statistiche</h4>
          </b-tooltip>
        </div>
      </div>
    </div>
    <div class="card-content">
      <section
        class="columns"
        style="flex-direction: column; align-items: stretch; align-content: stretch; margin-top: -2.5em"
      >
        <div class="column" style="text-align: left">
          <div class="columns">
            <div class="column">
              <h4 class="title is-6">Totale movimenti:</h4>
            </div>
            <div
              class="column"
            >{{$store.state.dati.length}} di cui {{movimentiNascosti}} nascost{{movimentiNascosti == 1 ? 'o' : 'i'}}</div>
          </div>
        </div>
        <div class="column" style="text-align: left">
          <div class="columns">
            <div class="column">
              <h4 class="title is-6">
                Categoria con le maggiori
                <u>entrate</u> sul totale:
              </h4>
            </div>
            <div
              class="column"
            >{{categoriaMaggioriEntrate.name}} ({{categoriaMaggioriEntrate.importo}} Euro)</div>
          </div>
        </div>
        <div class="column" style="text-align: left">
          <div class="columns">
            <div class="column">
              <h4 class="title is-6">
                Categoria con le maggiori
                <u>uscite</u> sul totale:
              </h4>
            </div>
            <div
              class="column"
            >{{categoriaMaggioriUscite.name}} ({{categoriaMaggioriUscite.importo}} Euro)</div>
          </div>
        </div>
        <div class="column" style="text-align: left">
          <div class="columns">
            <div class="column">
              <h4 class="title is-6">Categoria con il maggior numero di movimenti:</h4>
            </div>
            <div
              class="column"
            >{{categoriaMaggioriMovimenti.name}} ({{categoriaMaggioriMovimenti.number}})</div>
          </div>
        </div>
        <div class="column" style="text-align: left">
          <div class="columns">
            <div class="column">
              <h4 class="title is-6">Percentuale uso di contanti sul totale:</h4>
            </div>
            <div class="column">{{usoContanti}} %</div>
          </div>
        </div>
        <div class="column" style="text-align: left">
          <div class="columns">
            <div class="column">
              <h4
                class="title is-6"
              >Percentuale movimenti categorizzati come 'Altre spese' sul totale:</h4>
            </div>
            <div class="column">{{movimentiAltreSpese}} %</div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "statistiche",
  computed: {
    movimentiNascosti() {
      return (
        this.$store.state.dati.length - this.$store.getters.datiVisibili.length
      );
    },
    categoriaMaggioriEntrate() {
      let sumCategorie = this.$store.state.categorie.map(cat => 0);
      this.$store.getters.datiEntrate.forEach(element => {
        sumCategorie[element.categoria] += element.importo;
      });
      let maxEntrate = Math.max(...sumCategorie);
      let index = sumCategorie.indexOf(maxEntrate);
      return {
        name: this.$store.state.categorie[index].name,
        importo: Math.round(maxEntrate * 100) / 100
      };
    },
    categoriaMaggioriUscite() {
      let sumCategorie = this.$store.state.categorie.map(cat => 0);
      this.$store.getters.datiUscite.forEach(element => {
        sumCategorie[element.categoria] += element.importo;
      });
      let maxUscite = Math.min(...sumCategorie);
      let index = sumCategorie.indexOf(maxUscite);
      return {
        name: this.$store.state.categorie[index].name,
        importo: Math.round(maxUscite * 100) / 100
      };
    },
    categoriaMaggioriMovimenti() {
      let sumCategorie = this.$store.state.categorie.map(cat => 0);
      this.$store.getters.datiUscite.forEach(element => {
        sumCategorie[element.categoria] += 1;
      });
      let maxMovimenti = Math.max(...sumCategorie);
      let index = sumCategorie.indexOf(maxMovimenti);
      return {
        name: this.$store.state.categorie[index].name,
        number: maxMovimenti
      };
    },
    usoContanti() {
      let contantiIndex = this.$store.getters.contantiCategoriaIndex;
      let movimentiContanti = this.$store.getters.datiVisibili.filter(
        dato => dato.categoria == contantiIndex
      ).length;
      return (
        Math.round(
          (movimentiContanti / this.$store.getters.datiVisibili.length) *
            100 *
            100
        ) / 100
      );
    },
    movimentiAltreSpese() {
      let altreSpeseIndex = this.$store.getters.altreSpeseCategoriaIndex;
      let movimentiAltreSpese = this.$store.getters.datiVisibili.filter(
        dato => dato.categoria == altreSpeseIndex
      ).length;
      return (
        Math.round(
          (movimentiAltreSpese / this.$store.getters.datiVisibili.length) *
            100 *
            100
        ) / 100
      );
    }
  }
};
</script>