<template>
  <div class="card">
    <div class="card-content">
      <div class="columns is-centered">
        <div class="column is-narrow">
          <b-tooltip
            class="control"
            label="Questo grafico visualizza in cosa hai speso o guadagnato per un mese selezionato. I dati sono riportati sia come importi (a sinistra) che in percentuali (a destra)."
            position="is-bottom"
            type="is-light"
            :delay="600"
            multilined
            square
          >
            <b-icon class="control" icon="information" size="is-small"></b-icon>
            <h4 class="is-size-5">Dove spendi e guadagni</h4>
          </b-tooltip>
        </div>
      </div>

      <div class="columns is-centered is-multiline">
        <div class="column is-narrow">
          <b-field label-position="inside">
            <div slot="label">
              <b-tooltip
                class="control tipologiaTooltip"
                :label="`Le uscite e le entrate sono calcolate sommando i movimenti nella stessa categoria. Il dato riportato è quindi informativo senza ulteriori calcoli. Esempio: in caso di più conti, uno principale ed uno per acquisti online, si avrà un bonifico dal conto 'principale' verso quello 'online' ed un'uscita da quello 'online' per aver acquistato su internet. L'unica uscita sarà l'acquisto online e non i movimenti interni fra conti.`"
                position="is-bottom"
                type="is-light"
                :delay="600"
                size="is-large"
                multilined
                square
              >
                <b-icon class="control" icon="information" size="is-small"></b-icon>
              </b-tooltip>Tipologia
            </div>
            <b-select icon="earth" v-model="tipologiaFlusso" expanded>
              <option value="OUT">Uscite</option>
              <option value="IN">Entrate</option>
              <option value="BOTH">Entrate e Uscite</option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-narrow">
          <b-field label="Mese" label-position="inside">
            <b-datepicker
              type="month"
              placeholder="Click to select..."
              icon="calendar-today"
              v-model="selectedDate"
              :date-formatter="getMeseFormatter"
            ></b-datepicker>
          </b-field>
        </div>
        <div class="column">
          <b-field label-position="inside">
            <div slot="label">
              <b-tooltip
                class="control tipologiaTooltip"
                label="Usualmente, indicare il giorno di ricezione dello stipendio cosicchè da allineare l'inizio del mese virtuale in modo corretto."
                position="is-bottom"
                type="is-light"
                :delay="600"
                multilined
                square
              >
                <b-icon class="control" icon="information" size="is-small"></b-icon>
              </b-tooltip>Giorno inizio mese
            </div>
            <b-numberinput min="1" max="31" v-model="monthDayStart" required></b-numberinput>
          </b-field>
        </div>
      </div>
    </div>

    <b-modal
      :active.sync="isDettaglioMovimentiModalOpen"
      trap-focus
      aria-role="dialog"
      aria-modal
      has-modal-card
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Movimenti selezionati</p>
        </header>
        <section class="modal-card-body">
          <!-- <b-button
            @click="isDettaglioMovimentiModalOpen=false; $store.commit('switchToMovimentiView')"
          >Apri grande</b-button>-->
          <Categorizza ref="categorizza" :readOnly="true" @hook:mounted="loadMovimentiModal" />
        </section>
      </div>
    </b-modal>

    <div class="card-content">
      <section class="columns is-centered">
        <span class="column is-6">
          <CategoryChart
            :chartData="chartData"
            :flusso="tipologiaFlusso"
            @dettaglio-click="showMovimentiModal"
          />
        </span>
        <span class="column is-6">
          <CategoryRing
            :chartData="chartData"
            :flusso="tipologiaFlusso"
            @dettaglio-click="showMovimentiModal"
          />
        </span>
      </section>
    </div>
  </div>
</template>

<script>
import CategoryChart from "@/components/CategoryChart.vue";
import CategoryRing from "@/components/CategoryRing.vue";
import Categorizza from "@/components/import/Categorizza.vue";
import moment from "moment";

export default {
  name: "CategoryPanel",
  components: {
    CategoryChart,
    CategoryRing,
    Categorizza
  },
  data: function() {
    return {
      selectedDate: new Date(),
      tipologiaFlusso: "OUT",
      isDettaglioMovimentiModalOpen: false,
      clickedCategoriaIndex: -1
    };
  },
  methods: {
    updateDate(date) {
      this.selectedDate = date;
    },
    showMovimentiModal(newClickedCategoriaIndex) {
      this.clickedCategoriaIndex = newClickedCategoriaIndex;
      this.isDettaglioMovimentiModalOpen = true;
    },
    loadMovimentiModal() {
      this.$refs.categorizza.onCategoryGraficiClicked({
        monthDayStart: this.monthDayStart,
        selectedDate: this.selectedDate,
        clickedCategoriaIndex: this.clickedCategoriaIndex
      });
    },
    getMeseFormatter(date) {
      moment.locale("it");
      let displayMese = moment(date).format("MMMM YYYY");
      return displayMese.charAt(0).toUpperCase() + displayMese.substring(1);
    }
  },
  mounted() {
    this.updateDate(this.maxDate);
  },
  watch: {
    maxDate(newMaxDate) {
      this.selectedDate = newMaxDate;
    }
  },
  computed: {
    monthDayStart: {
      get() {
        return this.$store.state.monthDayStart;
      },
      set(value) {
        this.$store.dispatch("updateMonthDayStart", value)
      }
    },
    maxDate: function() {
      return new Date(
        Math.max.apply(
          null,
          this.$store.getters.datiVisibili.map(entry => entry.data_valuta)
        )
      );
    },
    chartData: function() {
      let self = this;

      function isEligibleDate(entry) {
        return (
          (entry.data_valuta.getDate() >= self.monthDayStart &&
            self.selectedDate.getMonth() == entry.data_valuta.getMonth() &&
            self.selectedDate.getYear() == entry.data_valuta.getYear()) ||
          (entry.data_valuta.getDate() < self.monthDayStart &&
            ((self.selectedDate.getMonth() ==
              entry.data_valuta.getMonth() - 1 &&
              self.selectedDate.getYear() == entry.data_valuta.getYear()) ||
              (self.selectedDate.getMonth() == 11 &&
                entry.data_valuta.getMonth() == 0 &&
                self.selectedDate.getYear() + 1 ==
                  entry.data_valuta.getYear())))
        );
      }

      let selectedMonthDati = [
        this.$store.getters.datiEntrate.filter(entry => isEligibleDate(entry)),
        this.$store.getters.datiUscite.filter(entry => isEligibleDate(entry))
      ];

      selectedMonthDati = selectedMonthDati.map(dataset => {
        let categoryDati = this.$store.state.categorie.map(entry => 0);
        dataset.forEach(element => {
          categoryDati[element.categoria] += element.importo;
        });
        return categoryDati;
      });

      let unionDati = this.$store.state.categorie.map(entry => 0);
      selectedMonthDati.forEach(dataset => {
        this.$store.state.categorie.forEach((element, index) => {
          unionDati[index] += dataset[index];
        });
      });

      return [
        unionDati.map(value => (value > 0 ? value : 0)),
        unionDati.map(value => (value < 0 ? value : 0))
      ];
    }
  }
};
</script>