<template>
  <div class="is-mobile">
    <div class="is-rounded">
      <div class="card-content columns" style="flex-direction: column">
        <div class="column">
          <b-field label="Data valuta" label-position="inside" grouped>
            <b-datepicker
              v-model="data_valuta"
              placeholder="Clicca per scegliere la data"
              icon="calendar-today"
            ></b-datepicker>
            <b-checkbox v-model="ugualeDate">Data contabile coincide</b-checkbox>
          </b-field>

          <b-field label="Data contabile" label-position="inside">
            <b-datepicker
              v-model="data_contabile"
              placeholder="Clicca per scegliere la data"
              icon="calendar-today"
              :disabled="ugualeDate"
            ></b-datepicker>
          </b-field>

          <b-field label="Descrizione" label-position="inside">
            <b-input maxlength="200" type="textarea" v-model="descrizione"></b-input>
          </b-field>

          <b-field label="Importo" label-position="inside" style="margin-top: -1.5em">
            <b-input type="text" v-cleave="masks.movimento" v-model="importo" required />
            <p class="control">
              <span class="button is-static">Euro</span>
            </p>
          </b-field>

          <b-field label="Categoria" label-position="inside">
            <b-select v-model="categoriaIndex" required>
              <template v-for="(categoria, index) in categorie">
                <option :value="index" :key="index">{{ categoria.name }}</option>
              </template>
            </b-select>
          </b-field>
        </div>

        <div class="column buttons is-centered">
          <b-button
            type="is-success"
            size="is-large"
            @click="submitMovimento"
            :disabled="notAllFieldsFilled"
          >{{labelDoneButton}}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";

import CleaveMixin from "@/mixins/CleaveMixin";

export default {
  name: "movimento",
  mixins: [CleaveMixin],
  props: ["labelDoneButton"],
  data: function() {
    let altreSpeseIndex = this.$store.getters.altreSpeseCategoriaIndex;
    return {
      data_valuta: null,
      data_contabile: null,
      ugualeDate: true,
      descrizione: "",
      importo: null,
      categoriaIndex: altreSpeseIndex
    };
  },
  computed: {
    categorie() {
      return this.$store.state.categorie;
    },
    notAllFieldsFilled() {
      return (
        this.data_valuta == null ||
        (!this.ugualeDate && this.data_contabile == null) ||
        this.descrizione == "" ||
        (this.importo == null ||
          this.importo == "" ||
          this.importo.replace(",", ".") == "-")
      );
    }
  },
  methods: {
    submitMovimento() {
      let newInsertedMovimento = this.$store.dispatch("loadSingleDato", {
        data_valuta: this.data_valuta,
        data_contabile: this.ugualeDate
          ? this.data_valuta
          : this.data_contabile,
        descrizione: this.descrizione,
        importo: parseFloat(this.importo.replace(",", ".")),
        categoriaIndex: this.categoriaIndex
      });

      newInsertedMovimento.then(newMovimentoId => {
        this.$emit("done", newMovimentoId);
      });
    }
  }
};
</script>